<template>
  <div class="cardDetailPage">
    <topbar title="提现">提现</topbar>
    <van-popover
      class="selectType"
      v-model="showPopover"
      trigger="click"
      :actions="actions"
      @select="onSelectType"
      placement="bottom"
    >
      <template #reference>
        <div class="withdrawType">
          <div class="title">提现方式</div>
          <div class="typeName">
            {{ accountType.text ? accountType.text : '请选择提现方式' }}
          </div>
          <div class="icon">
            <van-icon name="arrow" />
          </div>
        </div>
      </template>
    </van-popover>
    <div
      class="targetCard"
      v-if="userInfo.needTax && accountType.value != 3 && Object.keys(this.currentCard).length"
      @click="cardListShow = true"
    >
      <div class="title">提现至</div>
      <div class="logo">
        <img v-if="accountType.value == 1" src="@/assets/withdraw/bank1.png" alt="" />
        <img v-else-if="accountType.value == 2" src="@/assets/withdraw/ali1.png" alt="" />
        <img v-else-if="accountType.value == 3" src="@/assets/withdraw/wx1.png" alt="" />
      </div>
      <div class="bankInfo">
        <template v-if="accountType.value == 1">
          <div>{{ currentCard.bankName }}（{{ currentCard.accountNo }}）</div>
          <div>{{ currentCard.bankCardTypeName }}</div>
        </template>
        <template v-else-if="accountType.value == 2">
          <div>支付宝</div>
          <div>{{ currentCard.accountNo }}</div>
        </template>
        <template v-else>
          <div>微信零钱</div>
        </template>
      </div>
      <div class="icon">
        <van-icon name="arrow" />
      </div>
    </div>

    <div class="detail">
      <div class="pageLabel">可提现金额</div>
      <!-- <div class="total">
        <span>￥{{ total }}</span>
      </div> -->
      <div class="total">
        <span> ￥ </span>
        <van-field
          v-model="applyAmount"
          @input="getDeductionfee"
          type="number"
          :center="true"
          :formatter="formatterInput"
          :placeholder="`可提现金额￥${total}`"
        />
        <span
          @click="
            applyAmount = total
            getDeductionfee()
          "
          >全部提现</span
        >
      </div>
      <template v-if="applyAmount > 0">
        <div class="item">
          <span>申请可提现金额</span>
          <span>￥{{ amountInfo.applyAmount }}</span>
        </div>
        <div class="item">
          <span>提现手续费</span>
          <span>￥{{ amountInfo.withdrawFee }}</span>
        </div>
        <div class="item">
          <span>实际到账金额</span>
          <span>￥{{ amountInfo.actualAmount }}</span>
        </div>
      </template>

      <div class="text">
        每日可提现次数{{ amountInfo.limitNum }}次，每笔提现最低限额{{ amountInfo.withdrawMinimum }}
      </div>
      <div class="protocol" v-if="userInfo.needTax">
        <van-checkbox v-model="agreeProtocol">
          <span class="protocolText"> 我已阅读并同意</span> </van-checkbox
        >
        <!-- <a :href="protocol.url">《零工经济合作伙伴协议》</a> -->
        <span style="position: relative; bottom: 1px" @click="toProtocal">《自由职业者服务协议》</span>
      </div>
      <div class="submit">
        <van-button block type="danger" @click="submit" :disabled="taking">提现</van-button>
      </div>
    </div>
    <van-popup v-model="cardListShow" closeable position="bottom" :style="{ height: '50%' }">
      <div class="popupTitle">选择提现方式</div>
      <div class="accountList">
        <div
          v-for="(card, index) in cardTypeList"
          :key="'card' + index"
          @click="setCurrentCard(card)"
        >
          <BankCard :data="card" class="bankItem"></BankCard>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import topbar from '@/component/topbar.vue'
import BankCard from './__com__/card.vue'
export default {
  components: { topbar, BankCard },
  name: '',
  data() {
    return {
      cardListShow: false,
      total: 0,
      accountType: {},
      currentCard: {},
      applyAmount: '',
      amountInfo: {
        actualAmount: 0,
        applyAmount: 0,
        limitNum: 0,
        needTax: true,
        withdrawFee: 0,
        withdrawFeeRatio: 0,
        withdrawMinimum: 0
      },
      cardTypeList: [],
      protocol: {},
      agreeProtocol: false,
      showPopover: false,
      taking: false // 提现中
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo'
    }),
    lastFourNumber() {
      let noStr = String(this.currentCard.accountNo)
      let len = noStr.length
      return noStr.substr(noStr - 4, 4)
    },
    actions() {
      if (this.userInfo.needTax) {
        return [
          // { text: '银行卡', value: 1 },
          { text: '支付宝', value: 2 },
          // { text: '微信零钱', value: 3 }
        ]
      } else {
        return [{ text: '支付宝', value: 2 }]
      }
    }
  },
  created() {
    // this.accountType = this.actions[0]
    this.getDeductionfee()
    this.getData()
    // this.getProtocol()
    if (this.userInfo.needTax) {
      // 注释是否绑定银行卡 提醒
      // this.getCardTypeList()
    }
  },
  mounted() {},
  methods: {
    formatterInput(v) {
      let dec = String(v).split('.')
      if (String(v).split('.')[1]?.length > 2) {
        return Number(v).toFixed(2)
      }
      return v
    },
    onSelectType(v) {
      this.accountType = v
      if (v.value == 3) return
      this.getCardTypeList()
    },
    setCurrentCard(card) {
      this.currentCard = card
      this.cardListShow = false
    },
    // 费率
    getDeductionfee() {
      this.$http
        .get('/boom-center-user-service/app/distribution/withdraw-records/deduction-fee', {
          params: {
            applyAmount: Number(this.applyAmount) || 0
          }
        })
        .then(res => {
          this.amountInfo = { ...res }
        })
        .catch(err => {
          this.$notify({ type: 'danger', message: err.msg })
        })
        .finally(() => {})
    },
    getData() {
      this.$http
        .get('boom-center-user-service/app/distribution/withdraw-records/valid-amount-v1')
        .then(res => {
          if (!res.msg) {
            this.total = res.validAmount || 0
            // this.total = 1003
          }
        })
        .finally(() => {})
    },
    // 查询分销员收款账号列表
    getCardTypeList() {
      this.$http
        .get(`/boom-center-user-service/app/distribution/member/account/list`, {
          params: {
            // id: 0,
            accountType: this.accountType.value
          }
        })
        .then(res => {
          this.cardTypeList = res
          this.currentCard = this.cardTypeList[0] || {}
          if (!Object.keys(this.currentCard).length) {
            let msg, type
            if (this.accountType.value == 1) {
              msg = '没有提现银行卡,是否前去设置'
              type = 'bank'
            } else if (this.accountType.value == 2) {
              msg = '没有提现支付宝,是否前去设置'
              type = 'alipay'
            }
            this.$dialog
              .confirm({ title: '提示', message: msg })
              .then(() => {
                this.$router.push(`/setting/addBankCard?type=${type}`)
              })
              .catch(() => {
                // on cancel
              })
          }
        })
        .catch(err => {
          this.$notify({ type: 'danger', message: err.msg })
        })
        .finally(() => {})
    },
    submit() {
      if (this.total < this.applyAmount) {
        this.$notify({
          type: 'warning',
          message: '金额超过上限'
        })
        return
      }
      if (this.userInfo.needTax) {
        if (!this.agreeProtocol) {
          this.$notify({ type: 'warning', message: '提现前请阅读协议' })
          return
        }
      }
      let params = {
        accountType: this.accountType.value,
        applyAmount: this.applyAmount
      }
      if (this.accountType.value != 3) {
        if (!this.currentCard.id) {
          this.$notify({ type: 'warning', message: '没有提现账号' })
          return
        }
        params.accountId = this.currentCard.id
      }
      this.taking = true
      this.$http
        .post('/boom-center-user-service/app/distribution/withdraw-records', {
          accountId: this.currentCard.id,
          accountType: this.accountType.value,
          applyAmount: this.applyAmount
        },{
          headers: {
            'Bm-Area-Id': localStorage['areaId']
          }
        })
        .then(res => {
          this.$toast.success('提现成功')
          this.$router.push('/withdraw/success')
        })
        .catch(err => {
          this.$notify({ type: 'warning', message: err.msg })
        })
        .finally(() => {
          this.taking = false
        })
    },
    // 协议
    getProtocol(id) {
      this.$http
        .get(`/boom-center-third-party-service/app/yunzhanghu/contract/content`)
        .then(res => {
          this.protocol = res
        })
    },
    toProtocal(){
      this.$router.push('/protocol')
    }
  }
}
</script>
<style lang="less" scoped>
.cardDetailPage {
  background-color: #f5f5f5;
  height: 100vh;
  .selectType {
    width: 100%;
  }
  .withdrawType {
    margin: 15px 15px 0;
    padding: 12px 12px 11px;
    background-color: #fff;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title,
    .typeName {
      height: 20px;
      font-size: 15px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .typeName {
      flex: 1;
      padding-left: 50px;
    }
  }
  .targetCard {
    margin: 15px 15px 0;
    padding: 12px 12px 11px;
    height: 66px;
    background-color: #fff;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: flex-start;
    .title {
      height: 20px;
      font-size: 15px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .logo {
      margin-left: 31px;
      margin-right: 7px;
      img {
        width: 30px;
        height: 30px;
      }
    }
    .bankInfo {
      flex: 1;
      & > div {
        &:nth-child(1) {
          height: 21px;
          font-size: 15px;
          font-weight: 500;
          color: #1b1d21;
          line-height: 18px;
        }
        &:nth-child(2) {
          height: 20px;
          font-size: 14px;
          margin-top: 2px;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
        }
      }
    }
    .icon {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .detail {
    margin: 15px 15px 0;
    padding: 12px 12px 11px;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    .pageLabel {
      height: 20px;
      font-size: 15px;
      font-weight: 400;
      color: #333333;
    }
    .total {
      height: 35px;
      line-height: 35px;
      margin-top: 18px;
      margin-bottom: 14px;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.04);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      span {
        &:nth-of-type(1) {
          flex: 1;
          text-align: center;
          height: 35px;
          line-height: 35px;
          font-size: 30px;
          font-weight: 500;
          color: #1b1d21;
        }
        &:nth-of-type(2) {
          font-size: 12px;
          font-weight: 400;
          color: #e7141a;
          position: absolute;
          right: 0;
        }
      }
      /deep/.van-cell {
        font-size: 28px !important;
        input::placeholder {
          font-size: 14px !important;
        }
        input:-ms-input-placeholder {
          font-size: 14px !important;
        }
        input:-moz-input-placeholder {
          font-size: 14px !important;
        }
        input::-webkit-input-placeholder {
          font-size: 14px !important;
        }
      }
    }
    .item {
      margin-top: 8px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      & > span {
        display: inline-block;
        &:nth-child(1) {
          height: 18px;
          line-height: 15px;
          font-size: 13px;
          font-weight: 400;
          color: #474849;
        }
        &:nth-child(2) {
          height: 17px;
          line-height: 14px;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .text {
      margin-top: 8px;
      height: 17px;
      line-height: 14px;
      font-size: 12px;
      font-weight: 400;
      color: #ff891c;
    }
    .protocol {
      margin-top: 20px;
      font-size: 12px;
      display: flex;
      align-items: center;
      .protocolText {
        color: #666666;
        span {
          color: #1b1d21;
        }
      }
      a {
        color: #1b1d21;
        font-weight: 500;
        &:active {
          color: #666666;
        }
      }
    }
    .submit {
      margin-top: 13px;
    }
  }
  .popupTitle {
    text-align: center;
    padding: 20px 20px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
  .accountList {
    height: calc(100% - 60px);
    padding: 0 20px 50px;
    overflow: auto;
    box-sizing: border-box;
    .bankItem {
      margin-top: 20px;
    }
  }
}
</style>
