<template>
  <div class="personalCenter">
    <div class="topbar" v-if="!isMiniApp">
      <div class="title">分销中心</div>
    </div>
    <div class="bg"></div>
    <div class="contentBlock">
      <div class="notice" @click="$router.push('/gradeDesc')">
        <img src="@/assets/home/notice1.png" style="height: 18px; width: 18px" alt="" />
        <div class="text">提现功能已恢复，可自行申请提现~</div>
        <img src="@/assets/home/notice2.png" style="height: 14px; width: 14px" alt="" />
      </div>
      <div class="user">
        <div class="userInfo">
          <img class="avatar" :src="userInfo.avatarUrl" />
          <div class="info">
            <div style="display: flex; align-items: center">
              <span class="name">{{ userInfo.nickname }}</span>
            </div>
            <div class="id" @click="handleCopy">
              <span>ID:</span> <span>{{ userInfo.id }}</span>
              <img
                class="icon"
                src="https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/icon/common/icon_copy.png"
                alt=""
              />
            </div>
          </div>
          <div class="level">
            <span class="levelBtn" @click="linkTo('gradeDesc')">
              {{ income.levelName }}
            </span>
          </div>
        </div>
      </div>
      <div class="money-container">
        <div class="earnings">
          <div class="totalLabel">收入总额</div>
          <div class="totalIncome"><span class="char">￥</span>{{ income.totalIncome }}</div>
          <div @click="linkTo('income')" class="btn">
            <img src="@/assets/home/earning1.png" style="height: 13px; width: 13px" alt="" />
            <span>查看明细</span>
            <img src="@/assets/home/earning2.png" style="height: 12px; width: 12px" alt="" />
          </div>
        </div>
        <div class="withdraw">
          <div class="item">
            <div class="money">{{ income.pendingTotalAmount }}</div>
            <div class="label">待入账(元)</div>
          </div>
          <div class="item">
            <div class="money">{{ income.ableWithdrawAmount }}</div>
            <div class="label">待提现(元)</div>
          </div>
          <div class="item">
            <div class="money">{{ income.submittedWithdrawAmount }}</div>
            <div class="label">提现中(元)</div>
          </div>
        </div>
        <div
          style="width: 100%; text-align: center; font-size: 12px; margin-top: 5px; color: #999"
          @click="popup = true"
          v-if="income.frozenTotalAmount"
        >
          由于未达到{{ income.nextWithdrawLevelName }}有{{ income.frozenTotalAmount }}冻结
          <!-- <van-icon name="info-o" /> -->
        </div>
        <div class="btns">
          <div class="item">
            <div class="btn btn1" @click="linkTo('withdraw/list')">提现记录</div>
          </div>
          <div class="item" v-if="!isBan">
            <!-- <div class="item"> -->
            <div class="btn btn2" @click="toWithdraw">申请提现</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 引流人数 -->
    <div class="block">
      <div class="item" @click="linkTo('myTeam/all')">
        <div class="title">
          <img src="@/assets/home/block1.png" alt="" />
          引流人数
        </div>
        <div class="subtitle">查看引流（{{ teamNum.drainage }}）</div>
      </div>
      <div class="item" @click="linkTo('myTeam/share')">
        <div class="title">
          <img src="@/assets/home/block2.png" alt="" />
          单次推广
        </div>
        <div class="subtitle">查看推广（{{ teamNum.share }}）</div>
      </div>
      <div class="item" @click="linkTo('shareCode')">
        <div class="title">
          <img src="@/assets/home/block3.png" alt="" />
          邀请好友
        </div>
        <div class="subtitle">绑定推广获得佣金</div>
      </div>
    </div>
    <!-- 关注贪吃商城 -->
    <div class="follow" @click="jumpOfficalAccount">
      <!-- v-if="isWx || isMiniApp" -->
      <img
        class="logo"
        src="https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/miniapp/icon/common/logo.png"
      />
      <div class="text">
        <div v-if="isHotelTourism">关注‘贪吃旅行’服务号</div>
        <div v-else>关注‘贪吃商城’服务号</div>
        <div>实时收益提醒，收益享不断</div>
      </div>
      <div class="btn">关注</div>
    </div>

    <!-- 更多服务 -->
    <div class="menu-list">
      <div class="title">更多服务</div>
      <div class="list">
        <div class="item" @click="toMyStore" v-if="isMiniApp && openMyStore">
          <!-- <van-icon class="icon" name="shop-o" color="#3F51B5" /> -->
          <img class="icon" src="@/assets/home/menu1.png" alt="" />
          <div class="text">小店管理</div>
        </div>
        <div class="item" @click="linkTo('leaderBoard')">
          <!-- <van-icon class="icon" name="bar-chart-o" color="#FF5722" /> -->
          <img class="icon" src="@/assets/home/menu2.png" alt="" />
          <div class="text">收益排行</div>
        </div>
        <div class="item" @click="linkTo('gradeDesc')">
          <!-- <van-icon class="icon" name="cluster-o" color="#4CAF50" /> -->
          <img class="icon" src="@/assets/home/menu3.png" alt="" />
          <div class="text">分销规则</div>
        </div>
        <div class="item" @click="linkTo('setting')">
          <!-- <van-icon class="icon" name="setting-o" color="#607D8B" /> -->
          <img class="icon" src="@/assets/home/menu4.png" alt="" />
          <div class="text">设置</div>
        </div>
      </div>
    </div>
    <!-- 签约认证 -->
    <van-dialog
      v-model="dialogToSignPageShow"
      title="签约认证"
      :showConfirmButton="false"
      :show-cancel-button="false"
    >
      <div class="signDialog">
        <img src="@/assets/usersign/userSign1.png" />
        <div class="reason" v-html="signDesc">
          由于平台开启了个税代缴服务，需要认证身份证，请及时补充资料，否则平台无法给你发放佣金。
        </div>
        <div class="btn">
          <van-button size="small" block type="danger" @click="toUserSign">去设置</van-button>
          <div class="line"></div>
          <van-button size="small" block plain type="danger" @click="dialogToSignPageShow = false"
            >下次再说</van-button
          >
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Notify,Dialog } from 'vant'
export default {
  name: 'home',
  data() {
    return {
      income: {},
      loading: false,
      popup: false,
      qrcode: {
        show: false,
        img: ''
      },
      openMyStore: false,
      storeId: '',
      isHotelTourism: false,
      teamNum: {
        share: 0,
        drainage: 0
      },
      dialogToSignPageShow: false,
      signDesc: ''
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      isMiniApp: 'sys/isBmMiniApp',
      isBan: 'user/isBan',
      isWx: 'sys/isWx'
    }),
    level() {
      if (this.income.levelName) return this.income.levelName.replace('级', '')
      return 'C'
    }
  },
  created() {
    // 用户户未签约且是签约失效的状态，需提醒用户更新签约信息
    const {signStatus,loseEfficacy} =this.userInfo
    if(!signStatus && loseEfficacy){
      Dialog.confirm({
        title: '提示',
        message: '您的签约已失效,是否前去更新?',
      }).then(() => {
        this.$router.push('/userSign?step=2&update=true')
      }).catch(()=>{

      })
    }
    this.getMyStoreSetting()
  },
  mounted() {
    this.getIncome()
    this.getStore()
    this.getTeamNum()
    this.isHotelTourism = [7, 1999].indexOf(Number(localStorage['tenantId'])) > -1
    // this.getQrcode()
    // console.log('this.userinfo >>>>>>>>>>>>', this.userInfo)
  },
  methods: {
    toWithdraw() {
      if (this.userInfo.signStatus) {
        this.$router.push('/withdraw?replace=true')
      } else {
        this.getUserSignInfo()
        this.dialogToSignPageShow = true
      }
    },
    toUserSign() {
      this.$router.push('/userSign?step=2')
    },
    onRefresh() {
      this.getIncome()
    },
    linkTo(link) {
      this.$router.push('/' + link)
    },
    getMyStoreSetting() {
      this.$http.get('/boom-center-admin-service/app/distribution/store/setting').then(res => {
        this.openMyStore = res.enableDistributionStore
      })
    },
    toMyStore() {
      if (this.storeId) {
        wx.miniProgram.navigateTo({
          url: `/pageMyStore/goods/index?id=${this.storeId}`
        })
      } else {
        wx.miniProgram.navigateTo({
          url: '/pageMyStore/set/index'
        })
      }
    },
    getStore() {
      this.$http.get('boom-center-user-service/app/personal/shop').then(res => {
        this.storeId = res?.id
      })
    },
    getIncome() {
      this.loading = true
      this.$http
        .get('boom-center-user-service/app/distributionMember/getIncome')
        .then(res => {
          localStorage['withdrawLowerLimit'] = res.withdrawLowerLimit
          localStorage['withdrawUpperLimit'] = res.withdrawUpperLimit
          this.income = res
        })
        .finally(() => {
          this.loading = false
        })
    },
    getTeamNum() {
      this.$http.get('boom-center-user-service/app/distributionMember/myIncome').then(res => {
        this.teamNum.drainage = res.drainageNum
      })
      this.$http
        .get('boom-center-user-service/app/distributionMember/countShare?page=1')
        .then(res => {
          this.teamNum.share = res.total
        })
      // this.$http
      //   .get('boom-center-user-service/app/distributionMember/getDrainage?page=1')
      //   .then(res => {
      //     this.teamNum.drainage = res.total
      //   })
    },
    getQrcode() {
      this.$http.get('boom-center-user-service/app/distributionBill/wx-mp-qrcode').then(res => {
        if (res.displayFlag) {
          let now = new Date()
          let date = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
          if (localStorage[date] === 'true') {
            return
          } else {
            localStorage[date] = 'true'
          }
        }
        this.qrcode = {
          show: res.displayFlag,
          img: res.url
        }
      })
    },
    jumpOfficalAccount() {
      if (this.isHotelTourism) {
        window.location.href =
          'https://mp.weixin.qq.com/s?__biz=Mzg2MDc1MTI2Ng==&mid=2247483654&idx=1&sn=0ed316830d4322ffa18224378ac5f742&chksm=ce20d5cbf9575cdd78e0483c92e2bcdc5373b09898752d8b57d59d1f445ae734fb661ee0b967&token=498846283&lang=zh_CN#rd'
      } else {
        window.location.href =
          'https://mp.weixin.qq.com/s?__biz=MzUxMTczOTU0NA==&mid=2247498521&idx=1&sn=dd735606afd2b9b7d3cee2eed053192e&chksm=f96da8eece1a21f83a4836323ef3f30a4b41f21fb4193acf531fe9b2833b8c4b5bdfb0688c90&token=1393251728&lang=zh_CN#rd'
      }
    },
    handleCopy() {
      this.$copyText(this.userInfo.id).then(() => {
        Notify({ type: 'success', message: '复制成功' })
      })
    },
    getUserSignInfo() {
      this.$http
        .get('/boom-center-user-service/app/distribution-member-sign-infos')
        .then(res => {
          this.signDesc = res.signDesc
        })
        .catch(err => {})
        .finally(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.personalCenter {
  height: 100vh;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .topbar {
    position: sticky;
    top: 0;
    left: 0;
    color: #fff;
    background: #e7141a;
    z-index: 999;
    width: 100%;
    padding: 15px;
    margin: 0;
    .title {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 380px;
    // background: #e7141a;
    background-image: linear-gradient(#e7141a 0%, #e7141a 45%, rgba(251, 45, 26, 0) 100%);
  }

  .contentBlock {
    position: relative;
    margin: 15px 15px 0;
    .notice {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 34px;
      border-radius: 8px;
      background: #fef6f5;
      overflow: hidden;
      margin-bottom: 15px;
      color: #333;
      &:active {
        filter: brightness(85%);
      }
      img {
        &:nth-of-type(1) {
          margin-left: 10px;
        }
        &:nth-of-type(2) {
          margin-right: 10px;
        }
      }
      .text {
        padding: 7px 7px;
        flex: 1;
        font-size: 12px;
      }
    }
    .user {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      background: #fef6f5;
      padding: 10px;
      border-radius: 10px 10px 0 0;
      .userInfo {
        flex: 1;
        flex-shrink: 1;
        overflow: hidden;
        display: flex;
        align-items: center;
        .avatar {
          width: 34px;
          height: 34px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;
          .name {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            line-height: 16px;
          }

          .id {
            height: 12px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            line-height: 12px;
            .icon {
              width: 10px;
              height: auto;
              margin-left: 5px;
            }
          }
          .icon {
            width: 38px;
            height: auto;
          }
        }
        .level {
          min-width: 58px;
          .levelBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            background: linear-gradient(133deg, #fce8c6 0%, #fdc265 52%, #f5ca79 100%);
            border-radius: 3px 3px 3px 3px;
            min-width: 58px;
            color: #a14c07;
          }
        }
      }
      .withdrawRecord {
        color: #e7141a;
        border: 1px solid #e7141a;
        padding: 5px 15px;
        border-radius: 30px;
        font-size: 12px;
      }
    }
    .money-container {
      background: #fff;
      overflow: hidden;
      padding: 17px 20px 21px;
      .earnings {
        display: flex;
        flex-direction: column;
        align-items: center;
        .totalLabel {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          margin-bottom: 4px;
        }
        .totalIncome {
          height: 30px;
          line-height: 30px;
          font-size: 26px;
          font-weight: 500;
          color: #1b1d21;
          .char {
            font-size: 20px;
          }
        }
        .btn {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 5px 6px;
          font-size: 12px;
          background: #ffeddf;
          border-radius: 37px;
          font-weight: 400;
          color: #c6863f;
          img {
            &:nth-of-type(1) {
              margin-right: 2px;
            }
            &:nth-of-type(2) {
              margin-left: 2px;
            }
          }
          &:active {
            filter: brightness(0.95);
          }
        }
      }
      .withdraw {
        display: flex;
        justify-content: space-around;
        background-color: #fff;
        margin-top: 28px;
        .item {
          text-align: center;
          color: #333;
          .money {
            height: 20px;
            font-size: 18px;
            font-weight: 400;
            color: #1b1d21;
            line-height: 21px;
          }
          .label {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #474849;
            line-height: 16px;
          }
        }
      }
      .btns {
        display: flex;
        margin-top: 23px;
        justify-content: flex-end;
        .item {
          text-align: center;
          width: 142px;
          margin: 0 auto;
          .btn {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            color: #333;
            font-size: 14px;
            border-radius: 4px;
            &:active {
              filter: brightness(0.95);
            }
          }
          .btn1 {
            background: #fff;
            border: 1px solid #e7141a;
            color: #e7141a;
          }
          .btn2 {
            background: #e7141a;
            color: #fff;
          }
        }
      }
    }
  }

  .block {
    display: flex;
    margin: 15px 15px 0;
    .item {
      border-radius: 10px;
      padding: 10px;
      background: #fff;
      overflow: hidden;
      flex-grow: 1;
      flex-shrink: 1;
      & + .item {
        margin-left: 8px;
      }
      &:active {
        filter: brightness(95%);
      }
      .title {
        font-size: 14px;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .subtitle {
        font-size: 10px;
        color: #999;
      }
    }
  }
  .follow {
    margin: 15px 15px 0;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 11px;
    &:active {
      filter: brightness(95%);
    }
    .logo {
      width: 35px;
      height: 35px;
    }
    .text {
      flex: 1;
      overflow: hidden;
      font-size: 13px;
      color: #333;
      padding: 0 15px;
      font-size: 14px;
      font-weight: 500;
      div:nth-child(1) {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
      }
      div:nth-child(2) {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
    }
    .btn {
      border: 1px solid #e7141a;
      border-radius: 5px;
      color: #e7141a;
      height: fit-content;
      padding: 5px 20px;
      font-size: 12px;
    }
  }
  .menu-list {
    background: #fff;
    border-radius: 10px;
    // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
    margin: 15px 15px 0;
    padding: 10px;
    padding-bottom: 0;
    overflow: hidden;
    .title {
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 16px;
    }
    .list {
      .item {
        display: inline-block;
        width: 25%;
        text-align: center;
        background: #fff;
        padding: 15px 0;
        &:active {
          filter: brightness(95%);
        }
        .icon {
          font-size: 22px;
          height: 22px;
          width: 22px;
        }
        .text {
          font-size: 12px;
          color: #333;
          margin-top: 5px;
        }
      }
    }
  }
  .link {
    margin-top: 15px;
    .check {
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      background: #f9f9f9;
      border-radius: 5px;
      &:active {
        filter: brightness(95%);
      }
    }
  }
  .foot {
    // position: absolute;
    position: fixed;
    flex: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 15px;
    padding-bottom: 15px;
    padding-top: 10px;
    background: #fff;
    // border-radius: 15px 15px 0 0;
    .tip {
      font-size: 10px;
      text-align: center;
      color: rgba(0, 0, 0, 0.15);
    }
    .applyWithdraw {
      margin: 10px 0;
      background-color: #e7141a;
      color: #fff;
      border: none;
      border-radius: 5px;
    }
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .content {
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    position: relative;
    .close {
      position: relative;
      top: 5px;
      right: 5px;
      font-size: 30px;
      color: #fff;
      text-align: center;
      font-weight: bold;
    }
    .tip {
      font-size: 12px;
      color: #555;
      text-align: center;
    }
  }
}
.signDialog {
  padding: 0 16px 20px;
  text-align: center;
  .title {
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 19px;
  }
  img {
    margin-top: 11px;
    height: 123px;
  }
  .reason {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    line-height: 23px;
  }
  .btn {
    margin-top: 20px;
    .line {
      margin-top: 10px;
    }
  }
}
</style>
